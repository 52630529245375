.Landing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#Landing-scroll {
  height: 5vh;
  width: auto;
}

.Landing-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#Landing-hero-subtitle {
  font-size: 1.5em;
  max-width: 90vw;
}

#Landing-hero-title {
  font-size: 9em;
  font-weight: 100;
}

#Landing-info {
  max-width: 80vw;
}

@media only screen and (min-width: 768px) {
  #Landing-hero-subtitle {
    font-size: 2em;
  }

  #Landing-hero-title {
    font-size: 15em;
  }
}
