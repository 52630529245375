#TextGenerator {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2vh;
}

#TextGenerator-output {
  background-color: #65737e;
  color: #c0c5ce;

  min-height: 60vh;
  width: 100%;
  padding: 1em;

  border-radius: 6px;
  border: none;
  padding-bottom: 2vh;
}
