.Contact {
  background-color: #343d46;
  color: #c0c5ce;

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
